<template>
    <v-row>
        <v-col>
            <v-card style="height:95px;">
                <v-card-title class="d-flex justify-center">
                    <span class="text-body-2">ЮАНЬ / ЦБ / КНР</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <tr>
                            <td>
                                1 СNY
                            </td>
                            <td>
                                Китайский юань
                            </td>
                            <td>
                                <span :class="getColor(rub.last - rub.prelast)">{{ (rub.last -
                                    rub.prelast).toFixed(2) }}</span>
                            </td>
                            <td class="text-right">
                                {{ rub.last }} руб
                            </td>
                        </tr>
                        <tr>
                            <td>
                                1 RUB
                            </td>
                            <td>
                                Российский рубль
                            </td>
                            <td>
                                <span :class="getColor( 1/ cny.Value - 1/ cny.Previous)">
                                {{ (1/ cny.Value -
                                    1 / cny.Previous).toFixed(2) }}</span>
                            </td>
                            <td class="text-right">
                                {{ (1 / rub.last).toFixed(4) }} ¥
                            </td>
                        </tr>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'

export default {
    name: 'MultiCard',

    data: () => ({
        rub: [],
        cny: [],
        interval: null,
    }),
    methods:{
        getColor(val) {
            if (val >= 0) {
                return 'green--text';
            } else {
                return 'red--text';
            }
        },
    },
    created() {
        let self = this;
        this.interval = setInterval(() => {
            axios.get('http://kutakbaev.ru:8080/cny').then(res => {
                self.rub = res.data;
                console.log(self)
            })
            axios.get('https://www.cbr-xml-daily.ru/daily_json.js', { mode: 'no-cors' })
                .then(res => {
                    self.cny = res.data.Valute['CNY'];
                    console.log(self)
                });
        }, 60000);
    },
    beforeDestroy() {
        // prevent memory leak
        clearInterval(this.interval)
    },
}
</script>